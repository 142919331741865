.background_container {
    /*width: 100vw;*/
    height: 100vh;
    object-fit: cover;
    background-image: url("../../../public/assets/LandingPageBase.png");
}

.landing_page_nav_bar {
    /*height: 10rem;*/
    display: flex;
    flex-direction: row;
    width: 100%;
    position: absolute;
    justify-content: space-between;

}

.landing_page_company_text {
    display: inline-block;
    font-size: 10rem;
    /*height: 100%;*/
    font-family: "Roboto Mono", Helvetica, sans-serif;
    user-select: none;
}

p {
 user-select: none;
}

.your_digital_homepage {
    display: flex;
    font-size: 2rem;
    align-self: flex-end;
    font-family: "Roboto Mono", Helvetica, sans-serif;
    user-select: none;
}

.landing_page_hero_text_container {
    position: absolute;
    top: 60%;
    left: 15%;
    transform: translate(-50%, -50%);
    padding: 10px;
    border-radius: 5px;
}


@font-face {
    font-family: 'Roboto Mono';
    src: local('Roboto Mono'), url(../../../public/fonts/Roboto_Mono/static/RobotoMono-Regular.ttf) format('truetype');
}

.landing_page_hero_text {
    color: #000000;
    font-size: 4rem;
    font-family: "Roboto Mono", Helvetica, sans-serif;
    margin: 2rem;
}


.something {
    display: flex;
}

.signin_button_container {
    display: flex;
    align-items: center;
    margin: 1rem;
}

.landing_page_button {
    width: 10rem;
    height: 4rem;
}

.landing_page_monkey_image_container {
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    overflow: hidden;

}

.landing_page_monkey_image {
    position: relative;
    top: 23rem;
    left: 24rem;
    width: 100rem;
}

